<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="DISTRIBUTER PROFILE"
          :breadcrumb="[
            {
              label: 'Distributer',
            },
            { label: 'Distributer Profile' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-5 col-md-5 col-12" style="padding:5px 5px 5px 5px">
            <div
              class="card custom-card"
              style="
                height: 100%;
                
              "
            >
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder"
                        >Distributer Profile</b
                      >
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder"
                        >Distributer Profile</b
                      >
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <!-- img responsive mobile  -->
                  <div
                    class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                  >
                    <img
                      style="width: 40%; border-radius: 15px"
                      :src="profileImg"
                      alt=""
                    />
                    <br />
                    <span style="color: #00364f">
                      <p
                        v-if="masterDistributer.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                  <h5 class="text-start">
                    <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                  </h5>

                  <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Code : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.code }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Name : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.name }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOB : </b>
                      <span v-if="masterDistributer">
                        {{ dateTime(masterDistributer.dob) }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Gender : </b>
                      <span v-if="masterDistributer"
                        >{{ masterDistributer.gender }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Phone Number : </b>
                      <span v-if="masterDistributer"
                        >+91-{{ masterDistributer.phone }}</span
                      >
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Email Id : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.email }}</span>
                    </div>
                  </div>
                  <!-- img responsive Pc  -->
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center"
                  >
                    <img
                      style="width: 100%; border-radius: 15px; margin-top: -20px"
                      :src="profileImg"
                      alt=""
                    />
                    <span style="color: #00364f; width: 40%">
                      <p
                        v-if="masterDistributer.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>
                <div class="col-12" style="margin-left: 7px">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Address : </b>
                    <span v-if="masterDistributer">{{ masterDistributer.address }} {{ masterDistributer.city }} {{ masterDistributer.pin }} </span>
                
                  </div>
                  <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>State : </b>
                      <span v-if="masterDistributer.state">{{
                        masterDistributer.state.name
                      }}</span>
                    </div>
                </div>
                   <!-- bussiness Detais  -->
                   <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder">Business Details </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Firm Name: </b>
                      <span v-if="masterDistributer"
                        >{{ masterDistributer.bussinessName }}
                      </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Bussiness Pan: </b>
                      <span v-if="masterDistributer"
                        >{{ masterDistributer.bussinessPan }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOI: </b>
                      <span v-if="masterDistributer"
                        >{{ dateTime(masterDistributer.doi) }}
                      </span>
                    </div>
                    <!-- <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Specialization: </b>
                      <span v-if="masterDistributer"
                        >{{ masterDistributer.specialization }}
                      </span>
                    </div> -->

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Business Address : </b>
                      <span v-if="masterDistributer"
                        >{{ masterDistributer.bussinessAddress }},{{ masterDistributer.bussinessCity}} ,{{ masterDistributer.bussinessPin }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Business State : </b>
                      <span v-if="masterDistributer.business_state">{{
                        masterDistributer.business_state.name
                      }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>GST Number : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.gstin }} </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>CIN : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.cin }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>TAN : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.tds }} </span>
                    </div>

                  </div>
                </div>
                <!-- end  bussiness Detais  -->

                <div class="row mt-1">
                  <div class="col-12 text-end">
                    <button
                      type="button"
                      class="btn text-white btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#doccuments"
                      style="background-color: #f21300; margin-right: 5px"
                    >
                      <b
                        >Kyc Doccuments &nbsp;
                        <i class="fa fa-hand-pointer-o"></i>
                      </b>
                    </button>
                    <!-- <button type="button" class="btn btns text-white btn-sm"><b>Edit Profile &nbsp;
                              <font-awesome-icon icon="edit" />
                            </b></button> -->
                  </div>
                </div>

                <div
                  class="row mt-1"
                  style="background-color: #e3e3e3; border-radius: 15px"
                >
                  <div class="col-12 p-1">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>IP Address :</b>
                      <span v-if="masterDistributer">{{
                        masterDistributer.ipAddress
                      }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Last Login :</b>
                      <span v-if="masterDistributer">{{
                        dateTimeWithTime(masterDistributer.last_login)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-7 col-md-7 col-sm-7 d-none">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="row" v-if="wallet.id">
                  <div class="col-12">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                  </div>

                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Wallet Balance : </b>
                      <span v-if="wallet.id">Rs.{{ wallet.balanceAmount }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Virtual Account : </b>
                      <span v-if="wallet.id">{{ wallet.merchant_vpa }} </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Wallet Code : </b>
                      <span v-if="wallet.id">{{ wallet.wallet }} </span>
                    </div>

                    <span style="color: #00364f" v-if="wallet.id">
                      <p
                        v-if="wallet.status == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>
                <hr style="color: #e3e3e3" class="mt-2" />
                <!-- start list area  -->
                <div class="row mt-3" v-if="wallet.id">
                  <div class="col-xl-12 col-md-12 col-lg-12 text-start">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder"
                        >Beneficiary Account</b
                      >
                    </h4>
                  </div>
                  <!-- <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-end">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder"
                        ><button
                          @click="modalHeading"
                          type="button"
                          class="btn text-white btn-sm"
                          style="
                            background-color: #f21300;
                            font-size: 13px;
                            letter-spacing: 1px;
                          "
                          data-bs-toggle="modal"
                          data-bs-target="#createbeneficiary"
                        >
                          <b> <font-awesome-icon icon="plus" /> Create </b>
                        </button></b
                      >
                    </h4>
                  </div> -->

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <!-- <th></th> -->
                          <th><b>+</b></th>
                          <th>Name</th>

                          <th>Bank</th>
                          <th>Ifsc</th>
                          <th>Ac No.</th>
                          <th>Status</th>
                        </tr>

                        <tbody>
                          <tr v-for="(acount, index) in beneficiaryaccount" :key="index">
                            <td>
                              <img
                                style="width: 8px; margin-left: 8px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td>
                            <td>{{ acount.account_holder_name }}</td>
                            <td>{{ acount.bank_name }}</td>
                            <td>{{ acount.ifsc_code }}</td>
                            <td>{{ acount.account_number }}</td>

                            <td>
                              <p
                                v-if="acount.status == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                            <!--   -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!--start model  -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style="border-radius: 50px"
  >
    <div class="modal-dialog">
      <div class="modal-content" style="border-radius: 1.4vw">
        <div class="modal-header bg-white" style="border-radius: 1.4vw">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; font-size: 1.4vw"
          >
            <b>New Bank Account</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="
              edit == 'false' ? beneficiaryaccountAdd() : updatebeneficiaryAcount()
            "
          >
            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-right: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.name"
                    placeholder="Account Holder"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.name"
                    placeholder="Account Holder"
                  />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-left: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="number"
                    v-model="form.account_number"
                    class="form-control"
                    placeholder="Account Number"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="number"
                    v-model="form.account_number"
                    class="form-control"
                    placeholder="Account Number"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-right: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    v-model="form.ifsc"
                    class="form-control"
                    placeholder="IFSC "
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    v-model="form.ifsc"
                    class="form-control"
                    placeholder="IFSC "
                  />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-left: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    v-model="form.bank_name"
                    class="form-control"
                    placeholder="Bank Name"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    v-model="form.bank_name"
                    class="form-control"
                    placeholder="Bank Name"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="number"
                    v-model="form.phone"
                    class="form-control"
                    placeholder=" Mobile Number"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="number"
                    v-model="form.phone"
                    class="form-control"
                    placeholder=" Mobile Number"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="from-group">
                  <select
                    class="form-select"
                    v-model="form.status"
                    aria-label="Default select example"
                    placeholder="Status"
                  >
                    <option value="true">Active</option>
                    <option value="false">InActive</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->
  <!-- Modal kyc doccuments show  -->
  <div
    class="modal fade"
    id="doccuments"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Kyc Doccuments</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm text">
              <tr class="table-wrapper-scroll-y tr-head rounded-circle">
                <th>+</th>
                <th>Name</th>
                <th>Action</th>
              </tr>

              <tbody>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="adharFrontImg" alt="" />
                  </td>

                  <td><p>Aadhar Front</p></td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharFront.toLowerCase() == 'pdf'
                            ? openWindow(adharFrontImgPdf)
                            : openWindow(adharFrontImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="adharBackImg" alt="" />
                  </td>

                  <td>Aadhar Back</td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharBack.toLowerCase() == 'pdf'
                            ? openWindow(adharBackImgPdf)
                            : openWindow(adharBackImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="panImg" alt="" />
                  </td>

                  <td>
                    <p>Pan Card</p>
                  </td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycPan.toLowerCase() == 'pdf'
                            ? openWindow(panImgPdf)
                            : openWindow(panImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white btn-sm"
            data-bs-dismiss="modal"
            style="background-color: #f21300"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
import Banner from "../../../components/distributer/comman/Banner.vue";
import moment from "moment";
import Spinner from "../../../components/staff/comman/Spinner.vue";
import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "Distributer Profile",

  components: {
    Banner,

    Spinner,
  },
  data() {
    return {
      profileImg: this.$store.state.placeholderImg,
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPan: "",
      adharFrontImgPdf: "",
      adharBackImgPdf: "",
      panImgPdf: "",
      loading: false,
      services: [],
      wallet: {},
      beneficiaryaccounts: [],
      masterDistributer: {},
      form: new Form({
        bank_name: "",
        account_number: "",
        name: "",
        phone: "",
        ifsc: "",
        remark: "",
      }),
      edit: "false",
      beneficiaryId: null,
    };
  },
  methods: {
    openWindow(url) {
      window.open(`${url}`);
    },
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm ");
      }
      
    },
    downloadPdf(fileName) {
      window.open(fileName);
    },
    editBeneficiaryAcount(acount) {
      this.form.name = acount.account_holder_name;
      this.form.account_number = acount.account_number;
      this.form.ifsc = acount.ifsc_code;
      this.form.bank_name = acount.bank_name;
      this.form.phone = acount.mobile;
      this.form.status = acount.status;
      this.form.remark = acount.remark;
      this.form_id = acount.id;
      this.beneficiaryId = acount.id;
      this.edit = "true";
      // $('#modal-heading-update').html(heaing)
    },
    updatebeneficiaryAcount() {
      this.$axios
        .put(`masterdistributer/beneficiaryaccount/${this.beneficiaryId}`, this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(
            "____________create ___beneficiaryaccount______________in_________"
          );
          console.log(res.data.data.data);
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Beneficiary Acount Updated  Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
          this.form.name = "";
          this.form.account_number = null;
          this.form.ifsc = null;
          this.form.bank_name = "";
          this.form.phone = null;
          this.form.status = "";
          this.form_id = null;
          this.beneficiary_id = null;
          this.edit = "false";
          this.loadBeneficiaryAccount();
        });
    },

    beneficiaryaccountAdd() {
           this.$axios
        .post("masterdistributer/beneficiaryaccount", this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "The Beneficiary Account Created successfully.",
            showConfirmButton: false,
            timer: 1500,
          });

          this.distributerBeneficiaryaccount();
        })
        .catch((error) => {
          // error = error.data.message
          console.log(error);
        });
    },
    distributerWallet() {
      this.$axios
        .get(`staff/distributerwallet/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.wallet = res.data.data;
        });
    },
    distributerBeneficiaryaccount() {
      this.$axios
        .get(
          `staff/distributerbeneficiaryaccount?distributer_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(
            "__________beneficiaryaccount_________________________________________"
          );
          console.log(res.data.data.data);
          this.beneficiaryaccounts = res.data.data.data;
        });
    },
    loadDistributer() {
      // alert('ho')
      // console.log('==========654654645')
      // this.loading = true
      this.$axios
        .get(`staff/distributer/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data)
          this.masterDistributer = res.data.data;

        

          if (this.masterDistributer.photo != null) {
            if (
              this.$store.state.imgUrl +
              "/distributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.photo
            ) {
              this.profileImg =
                this.$store.state.imgUrl +
                "/distributer/" +
                this.masterDistributer.id +
                "/" +
                this.masterDistributer.photo;
            }
            if (
              this.$store.state.imgUrl +
              "/distributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.photo
            ) {
              this.profileImg =
                this.$store.state.imgUrl +
                "/distributer/" +
                this.masterDistributer.id +
                "/thumbs/" +
                this.masterDistributer.photo;
            }
          } else {
            this.profileImg = this.$store.state.placeholderImg;
          }

          if (this.masterDistributer.distributer_kyc.aadharFrontPhoto != null) {
           
            if (
              this.$store.state.imgUrl +
              "/distributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.distributer_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/distributer/" +
                this.masterDistributer.id +
                "/thumbs/" +
                this.masterDistributer.distributer_kyc.aadharFrontPhoto;
              this.aadharFrontPdf = this.adharFrontImg;
              //   alert(this.aadharFrontPdf)
            }

            if (
              this.$store.state.imgUrl +
              "/distributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.distributer_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/distributer/" +
                this.masterDistributer.id +
                "/" +
                this.masterDistributer.distributer_kyc.aadharFrontPhoto;
           
            }
             this.adharFrontImgPdf = this.adharFrontImg
            this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

            if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
              this.adharFrontImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharFrontImg = this.$store.state.placeholderImg;
          }

          if ((this.masterDistributer.distributer_kyc.aadharBackPhoto != null) != null) {
            if (
              this.$store.state.imgUrl +
              "/distributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.distributer_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/distributer/" +
                this.masterDistributer.id +
                "/thumbs/" +
                this.masterDistributer.distributer_kyc.aadharBackPhoto;
        
            }
            if (
              this.$store.state.imgUrl +
              "/distributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.distributer_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/distributer/" +
                this.masterDistributer.id +
                "/" +
                this.masterDistributer.distributer_kyc.aadharBackPhoto;
            
            }
            this.adharBackImgPdf = this.adharBackImg;
            this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

            if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
              this.adharBackImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharBackImg = this.$store.state.placeholderImg;
          }

          if (this.masterDistributer.distributer_kyc.panPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/distributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.distributer_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/distributer/" +
                this.masterDistributer.id +
                "/thumbs/" +
                this.masterDistributer.distributer_kyc.panPhoto;
    
            }
            if (
              this.$store.state.imgUrl +
              "/distributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.distributer_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/distributer/" +
                this.masterDistributer.id +
                "/" +
                this.masterDistributer.distributer_kyc.panPhoto;
             
            }
            this.panImgPdf = this.panImg;
            this.extensionkycPan = this.panImg.split(".").pop();

            if (this.extensionkycPan == "pdf" || this.extensionkycPan == "PDF") {
              this.panImg = "/assets/image/pdf.png";
            }
          } else {
            this.panImg = this.$store.state.placeholderImg;
          }
        });
      // .finally(() => (this.loading = false))
    },
    loadPageTitle(){
        document.title =this.$store.state.staffPageTitles.distributerView
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadDistributer();
      this.distributerWallet();
      this.distributerBeneficiaryaccount();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}


.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
